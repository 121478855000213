import store from "./../store/index.js";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import useCallableFunction from "@/hooks/useCallableFunction";

export default class Company {
  constructor(obj) {
    this.id = obj.id || "";
    this.name = obj.name || "";
    this.country = obj.country || "";
    this.keywords = obj.keywords || [];
    this.internal = obj.internal || false;
    this.emailNotifications = obj.emailNotifications || [];
    this.allAdminEmailNotifications = obj.allAdminEmailNotifications || false;
    this.description = obj.description || "";
    this.hourFormat = obj.hourFormat || "12";
    this.shard = obj.shard || "";
    this.maxAgents = parseInt(obj.maxAgents) || 10;
    this.actualAgents = parseInt(obj.actualAgents) || 0;
    this.tenant = obj.tenant || null;
    this.billingId = obj.billingId || null;
    this.maxStorage = obj.maxStorage || 100;
    this.usedStorage = obj.usedStorage || 0;
    this.billedSeconds = obj.billedSeconds || 0;
    this.secondsLimitPerCompany = parseInt(obj.secondsLimitPerCompany) || null;
  }

  static async getAll() {
    const db = getFirestore();
    const type = store.state.Auth.token.role;
    if (type === "superuser") {
      const querySnapshot = await getDocs(collection(db, "companies"));

      return querySnapshot.docs.map((company) => {
        return new Company({
          ...company.data(),
          id: company.id,
        });
      });
    } else if (type === "admin") {
      const company = store.state.Auth.token.claims.user_id.split("@")[1];
      const db = getFirestore();

      const docRef = doc(db, "companies", company);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return [
          new Company({
            ...docSnap.data(),
            id: company,
          }),
        ];
      }
    }
  }

  static async getById(id) {
    const db = getFirestore();

    const docRef = doc(db, "companies", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return new Company(docSnap.data());
    } else {
      return null;
    }
  }

  static async remove(company) {
    const removeCompany = useCallableFunction("api/removeCompany");
    const result = await removeCompany({ company });

    return result;
  }

  static async save(company) {
    try {
      const idComp = company.id.toString();
      const companySAVE = company.getObject(true);
      companySAVE.id = idComp;

      const obj = {
        company: companySAVE,
      };

      const addCompany = useCallableFunction("api/addCompany");
      const result = await addCompany(obj);

      if (!result.data.company) return null;
      return new Company(result.data.company);
    } catch (err) {
      console.log(err);
    }
  }

  handleUsageLimit(isSave = false) {
    const usageLimit = this.secondsLimitPerCompany;
    if (usageLimit && usageLimit > 0) {
      if (isSave) {
        return usageLimit * 60;
      } else {
        return usageLimit;
      }
    }
    return null;
  }

  getObject(isSave = false) {
    return {
      name: this.name,
      country: this.country,
      description: this.description,
      keywords: this.keywords,
      internal: this.internal,
      emailNotifications: this.emailNotifications,
      allAdminEmailNotifications: this.allAdminEmailNotifications,
      hourFormat: this.hourFormat,
      shard: this.shard,
      maxAgents: this.maxAgents,
      actualAgents: this.actualAgents,
      tenant: this.tenant,
      billingId: this.billingId,
      usedStorage: this.usedStorage,
      maxStorage: this.maxStorage,
      billedSeconds: this.billedSeconds,
      secondsLimitPerCompany: this.handleUsageLimit(isSave),
    };
  }

  static async saveConfiguration(type, company, config) {
    try {
      const obj = {
        company,
        type,
        config,
      };
      const saveConfiguration = useCallableFunction("api/saveConfiguration");
      const result = await saveConfiguration(obj);
      return result;
    } catch (err) {
      console.log(err);
    }
  }

  static async getConfiguration(type, company) {
    const db = getFirestore();

    const docRef = doc(db, `companies/${company.id}/configurations`, type);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      if (type === "callback") {
        return {
          url: null,
          secret: null,
          enabled: false,
        };
      } else {
        return {
          inboundChannel: null,
          outboundChannel: null,
        };
      }
    }
  }

  static async createToken(company, newToken) {
    try {
      const obj = {
        company,
        newToken,
      };
      const createToken = useCallableFunction("api/createToken");
      const result = await createToken(obj);
      return result.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async updateTokens(tokens, company) {
    try {
      const obj = {
        company,
        tokens,
      };
      const updateTokens = useCallableFunction("api/updateTokens");
      const result = await updateTokens(obj);
      return result.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async getTokens(company) {
    try {
      const getTokens = useCallableFunction("api/getTokens");
      const result = await getTokens({ company });
      return result.data;
    } catch (err) {
      console.log(err);
    }
  }
}
