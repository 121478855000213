import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import useCallableFunction from "@/hooks/useCallableFunction";

export default class User {
  constructor(obj) {
    this.id = obj.id || "";
    this.fullname = obj.fullname || "";
    this.avatar = obj.avatar || "";
    this.externalid = obj.externalid || "";
    this.description = obj.description || "";
    this.type = obj.type || "";
    this.password = obj.password || "";
    this.email = obj.email || "";
    this.phone = obj.phone || "";
    this.disabled = obj.disabled || false;
    this.recordings = obj.recordings || "";
    this.lang = obj.lang || navigator.language.substring(0, 5);
    this.recordEnabled = obj.recordEnabled || "both";
    this.storageUsed = obj.storageUsed || 0;
    this.isLocked = obj.isLocked || false;
  }

  static async getAll(companyId) {
    const db = getFirestore();
    const querySnapshot = await getDocs(
      collection(db, `companies/${companyId}/users`)
    );

    const result = querySnapshot.docs.map((user) => {
      return new User({
        ...user.data(),
        id: user.id,
      });
    });

    return result;
  }

  static async update(user, companyId) {
    user.id = user.id + "@" + companyId;
    const userUpdate = useCallableFunction("api/userUpdate");
    const result = await userUpdate({ user });
    return result;
  }

  static async add(user, companyId) {
    user.id = user.id + "@" + companyId;
    const userAdd = useCallableFunction("api/userAdd");
    const result = await userAdd({ user });
    return result;
  }

  static async remove(user, companyId) {
    user.id = user.id + "@" + companyId;
    const userDelete = useCallableFunction("api/userDelete");
    const result = await userDelete({ user });
    return result;
  }

  static async unlock(user, companyId) {
    const userUnlock = useCallableFunction("api/userUnlock");
    const result = await userUnlock({ user: user.id, companyId });
    return result;
  }

  static async getUserData(id, company) {
    const db = getFirestore();
    const docRef = doc(db, `companies/${company}/users`, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const result = docSnap.data();
      const { type, ...rest } = result;
      return {
        role: type,
        ...rest,
      };
    } else {
      return null;
    }
  }
}
