<template>
  <!--SETTINGS-->

  <v-menu
    nudge-bottom="60px"
    transition="slide-x-transition"
    :close-on-click="true"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" class="mr-2">
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
    </template>

    <v-list v-if="!incall" style="width: 222px">
      <!-- Admin item -->

      <v-list-item
        v-if="$store.state.Auth.token.role !== 'agent'"
        to="/admin"
        class="pa-0"
        style="text-decoration: none !important"
      >
        <v-list-item-content class="pa-0">
          <div style="padding: 10px 25px 10px 15px">
            <span>
              <v-icon class="pr-2">mdi-account</v-icon>
              Admin
            </span>
          </div>
        </v-list-item-content>
      </v-list-item>

      <!-- Appearance Item -->
      <v-list-item class="pa-0">
        <v-list-item-content class="pa-0">
          <v-menu
            open-on-hover
            :close-on-content-click="false"
            left
            nudge-left="182px"
            nudge-top="10px"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on" style="padding: 10px 25px 10px 15px">
                <span>
                  <v-icon class="pr-2">mdi-chevron-left</v-icon>
                  <v-icon class="pr-1">mdi-palette</v-icon>
                  {{ $i18n.t("SETTING.appearance") }}
                </span>
              </div>
            </template>

            <v-card class="px-4 py-2 apparence-card" style="height: 378px">
              <v-list style="padding: 3px 0px 0px 0px">
                <v-list-item two-line>
                  <v-icon size="32"> mdi-collage </v-icon>
                  <v-list-item-content class="pl-4">
                    <v-list-item-title
                      style="font-size: 14px; font-weight: 400; opacity: 0.9"
                    >
                      {{ $i18n.t("SETTING.appearance").toUpperCase() }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="font-size: 12px; font-weight: 300; opacity: 0.9"
                    >
                      {{ $i18n.t("SETTING.selectAppearance") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider />

              <v-row no-gutters class="px-6" style="height: 50px">
                <v-col style="display: flex">
                  <span
                    class="pr-4 pt-4"
                    style="font-size: 14px; font-weight: 400; opacity: 0.9"
                  >
                    {{ $i18n.t("SETTING.lightMode") }}
                  </span>
                  <v-switch
                    class="pt-4"
                    style="margin-top: 0px !important"
                    color="primary"
                    @change="setTheme"
                    :input-value="$vuetify.theme.dark"
                  />
                  <span
                    class="pl-4 pt-4"
                    style="font-size: 14px; font-weight: 400; opacity: 0.9"
                  >
                    {{ $i18n.t("SETTING.darkMode") }}
                  </span>
                </v-col>
              </v-row>

              <v-divider />

              <v-row no-gutters class="pt-3 px-2">
                <v-col
                  cols="6"
                  class="py-2 px-10"
                  v-for="(group, index) in colorGroups"
                  :key="index"
                >
                  <v-card
                    class="apparence-card__item"
                    :color="group"
                    style="
                      border-radius: 10px;
                      box-shadow: none;
                      height: 38px !important;
                    "
                  >
                    <div @click="setColor(group)" class="hover-div">
                      <v-icon>mdi-check</v-icon>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-menu>
        </v-list-item-content>
      </v-list-item>

      <!-- Language Item-->
      <v-list-item class="pa-0">
        <v-list-item-content class="pa-0">
          <v-menu
            open-on-hover
            :close-on-content-click="false"
            left
            nudge-left="182px"
            nudge-top="10px"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on" style="padding: 10px 25px 10px 15px">
                <span>
                  <v-icon class="pr-2">mdi-chevron-left</v-icon>
                  <v-icon class="pr-1">mdi-web</v-icon>
                  {{ $i18n.t("SETTING.lenguage") }}
                </span>
              </div>
            </template>
            <v-card
              class="px-2 py-2 apparence-card"
              height="230px"
              width="275px"
            >
              <v-list style="padding: 3px 0px 0px 0px">
                <v-list-item two-line>
                  <v-icon size="32"> mdi-web </v-icon>
                  <v-list-item-content class="pl-4">
                    <v-list-item-title
                      style="font-size: 14px; font-weight: 400; opacity: 0.9"
                    >
                      {{ $i18n.t("SETTING.lenguage").toUpperCase() }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="font-size: 12px; font-weight: 300; opacity: 0.9"
                    >
                      {{ $i18n.t("SETTING.selectLenguage") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider />
              <v-list class="px-4" rounded>
                <v-list-item-group v-model="language" color="primary">
                  <v-list-item
                    @click.prevent="setLang(item.key)"
                    v-for="item in langs"
                    :key="item.key"
                  >
                    <v-list-item-content class="text-center">
                      <v-list-item-title
                        style="font-size: 15px; font-weight: 400; opacity: 0.9"
                      >
                        {{ item.value }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-list-item-content>
      </v-list-item>

      <!-- Time Setting Item -->
      <v-list-item class="pa-0">
        <v-list-item-content class="pa-0">
          <v-menu
            open-on-hover
            :close-on-content-click="false"
            left
            nudge-left="182px"
            nudge-top="10px"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on" style="padding: 10px 25px 10px 15px">
                <span>
                  <v-icon class="pr-2">mdi-chevron-left</v-icon>
                  <v-icon class="pr-1">mdi-clock-time-eight-outline</v-icon>
                  {{ $i18n.t("SETTING.time") }}
                </span>
              </div>
            </template>
            <v-card
              class="px-2 py-2 apparence-card"
              height="230px"
              width="275px"
            >
              <v-list style="padding: 3px 0px 0px 0px">
                <v-list-item two-line>
                  <v-icon size="32"> mdi-clock-time-eight-outline </v-icon>
                  <v-list-item-content class="pl-4">
                    <v-list-item-title
                      style="font-size: 14px; font-weight: 400; opacity: 0.9"
                    >
                      {{ $i18n.t("SETTING.time").toUpperCase() }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="font-size: 12px; font-weight: 300; opacity: 0.9"
                    >
                      {{ $i18n.t("SETTING.selectTime") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider />
              <v-list class="px-4" rounded>
                <v-list-item-group v-model="timeSetting" color="primary">
                  <v-list-item
                    @click.prevent="setTime(item.key)"
                    v-for="item in time"
                    :key="item.key"
                  >
                    <v-list-item-content class="text-center">
                      <v-list-item-title
                        style="font-size: 15px; font-weight: 400; opacity: 0.9"
                      >
                        {{ item.value }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="pa-0" @click="modalChangePassword = true">
        <v-list-item-content class="pa-0">
          <div style="padding: 10px 25px 10px 15px">
            <span>
              <v-icon class="pr-2">mdi-lock</v-icon>
              {{ $i18n.t("SETTING.EDITPASS") }}
            </span>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-dialog v-model="modalChangePassword" max-width="500">
      <v-card>
        <v-card-title class="justify-center" style="color: #525252">
          <v-icon class="mr-2"> mdi-lock </v-icon>
          {{ $i18n.t("SETTING.EDITPASS") }}
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pa-4">
          <v-text-field
            prepend-icon="mdi-lock"
            filled
            rounded
            dense
            v-model="actualPassword"
            type="password"
            :rules="actualPasswordRule"
            :label="$i18n.t('SETTING.ACTUALPASS')"
            required
          />
          <v-divider />

          <v-text-field
            prepend-icon="mdi-lock"
            filled
            rounded
            dense
            v-model="newPassword"
            type="password"
            :rules="newPasswordRule"
            :label="$i18n.t('SETTING.NEWPASS')"
            required
          />

          <v-text-field
            prepend-icon="mdi-lock"
            filled
            rounded
            dense
            v-model="newPasswordConfirm"
            type="password"
            :rules="validateSamePassword"
            :label="$i18n.t('SETTING.CONFNEWPASS')"
            required
          />

          <v-btn
            style="float: right"
            :loading="loadingButton"
            :disabled="!valid"
            color="primary"
            class="mr-4"
            @click="changePassword"
          >
            {{ $i18n.t("GENERAL.confirm") }}
          </v-btn>
          <v-spacer style="clear: both" />
        </v-form>
      </v-card>
    </v-dialog>
  </v-menu>
</template>

<script>
import Login from "../models/login";

export default {
  props: {
    incall: Boolean,
  },
  data() {
    return {
      colorGroups: [
        "#002540",
        "#1885b5",
        "#90ce4d",
        "#ff9800",
        "#f44349",
        "#333333",
      ],
      language: this.$store.state.lang === "es" ? 0 : 1,
      timeSetting: this.$store.state.time === "12" ? 0 : 1,
      modalChangePassword: false,
      loadingButton: false,
      langs: [
        { key: "es", value: "Español" },
        { key: "en", value: "English" },
        { key: "pt", value: "Português" },
      ],
      time: [
        { key: "12", value: "12:00 AM" },
        { key: "24", value: "24:00" },
      ],
      valid: false,

      actualPassword: "",
      newPassword: "",
      newPasswordConfirm: "",

      actualPasswordRule: [(v) => !!v || "Required"],
      newPasswordRule: [(v) => !!v || "Required"],
    };
  },
  computed: {
    validateSamePassword() {
      return [(v) => v === this.newPassword || "Passwords has to be the same"];
    },
  },
  mounted() {},
  methods: {
    async changePassword() {
      const form = this.$refs.form;
      if (form.validate()) {
        this.loadingButton = true;

        const user = this.$store.state.Auth.token.claims.sub;
        const result = await Login.changePassword(
          user,
          this.actualPassword,
          this.newPassword
        );
        if (!result.error) {
          let type;
          let text;
          if (result.data) {
            type = "success";
            text = "Password succesfully changed";
          } else {
            type = "error";
            text = "Invalid Credentials";
          }
          this.$notify({
            group: "feedback",
            duration: 5000,
            type,
            title: "",
            text,
          });
        } else {
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "",
            text: "There was an error changing the password",
          });
        }
        this.loadingButton = false;
        this.modalChangePassword = false;
      }
    },
    setTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$store.dispatch("changeTheme", this.$vuetify.theme.dark);
    },

    setColor(color) {
      this.$vuetify.theme.themes.dark.primary = color;
      this.$vuetify.theme.themes.light.primary = color;
      this.$store.dispatch("changePrimary", color);
    },

    async setLang(lang) {
      await this.$store.dispatch("changeLang", lang);
    },

    async setTime(time) {
      await this.$store.dispatch("changeTime", time);
    },
  },
};
</script>
<style scoped>
.hover-vcard {
  transition: opacity 0.4s ease-in-out;
}

.hover-vcard:not(.on-hover) {
  opacity: 0.6;
}

.show-btns {
  display: block !important;
}
</style>

<style lang="scss">
.apparence-card {
  width: 300px;
  height: 320px;
}

.room-card {
  width: 350px;
}

.smallSettings {
  height: 604px !important;
}

.apparence-card__item {
  width: 85px !important;
  height: 30px !important;
  transition: all 0.3s;

  .hover-div {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.37);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    transition: all 0.3s;
    cursor: pointer;

    i {
      color: #fff;
      margin-top: 8px;
      margin: 10px 12px;
    }
  }
}

.apparence-card__item:hover {
  .hover-div {
    opacity: 100;
  }
}

.docsLink:hover {
  text-decoration: none;
}
</style>
