import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, signOut, signInWithCustomToken } from "firebase/auth";

export default class Login {
  static async login(username, password, lang) {
    const functions = getFunctions();

    const Login = httpsCallable(functions, "api/userLogin", {
      timeout: 540000,
    });

    const result = await Login({
      id: username,
      password,
      lang,
    });
    if (result.data.maskedEmail) {
      return result.data.maskedEmail;
    }
  }

  static async logout() {
    const auth = getAuth();
    await signOut(auth);
  }

  static async confirmCode(username, code) {
    const functions = getFunctions();

    const Confirm = httpsCallable(functions, "api/confirmCode", {
      timeout: 540000,
    });
    const result = await Confirm({
      id: username,
      code,
    });
    if (result.data.token) {
      const auth = getAuth();
      signInWithCustomToken(auth, result.data.token);
    }
  }

  static async resendCode(username, lang) {
    const functions = getFunctions();

    const resendCode = httpsCallable(functions, "api/resendCode");

    await resendCode({
      id: username,
      lang,
    });
  }

  static async changePassword(user, password, newPassword) {
    try {
      const functions = getFunctions();

      const changePassword = httpsCallable(functions, "api/changePassword");

      const result = await changePassword({
        password,
        newPassword,
      });
      return { data: result && result.data.succeeded };
    } catch (err) {
      return { error: err };
    }
  }
}
