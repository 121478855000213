import User from "@/models/user.js";
import router from "../../router/index.js";
import store from "../index";

export const namespaced = true;

export const state = {
  isLogged: false,
};

export const mutations = {
  SET_SESSION(state, value) {
    state.isLogged = value;
  },

  SET_TOKEN(state, token) {
    state.token = token;
  },
};

export const actions = {
  async userLogout({ commit }) {
    commit("SET_TOKEN", null);
    commit("SET_SESSION", false);
    store.commit("resetSelectedUser");

    if (router.currentRoute.meta.requiresAuth)
      router.replace({ name: "login" });
  },

  async userLogin({ commit }, user) {
    try {
      const token = await user.getIdTokenResult();
      const [id, company] = user.uid.split("@");
      const userData = await User.getUserData(id, company);
      commit("SET_TOKEN", { ...token, ...userData });
      if (token.claims.type !== "guest") {
        commit("SET_SESSION", true);
        if (router.currentRoute.name === "login") router.replace("/");
      }
    } catch (err) {
      commit("SET_TOKEN", null);
      commit("SET_SESSION", false);
      if (router.currentRoute.meta.requiresAuth)
        router.replace({ name: "login" });
    }
  },
};
