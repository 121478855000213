import useCallableFunction from "./useCallableFunction";
import store from "./../store/index.js";
import Company from "./../models/company";
import { isNumber } from "@/utils/numbers";

const getCompany = async () => {
  const companyId = store.state.Auth.token.claims.user_id.split("@")[1];
  return await Company.getById(companyId);
};

export const useValidateUsageLimit = async () => {
  const company = await getCompany();
  const { secondsLimitPerCompany, id } = company;

  const getMonthlySeconds = useCallableFunction("api/getMonthlySeconds");
  const {
    data: { monthlySeconds },
  } = await getMonthlySeconds({
    company: id,
  });

  const response = { company, monthlySeconds };

  if (
    isNumber(secondsLimitPerCompany) &&
    isNumber(monthlySeconds) &&
    secondsLimitPerCompany < monthlySeconds
  ) {
    return {
      ...response,
      limitReached: true,
    };
  }

  return {
    ...response,
    limitReached: false,
  };
};
